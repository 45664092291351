@tailwind base;
@tailwind components;
@tailwind utilities;

.test {
  color: red;
  background-color: green;

  border: 1px dashed black;
}
